import { Tile } from './TileV2'
import { tile1, tile2, tile3, tile4, tile5, tile6, tile7, tile8 } from './tiles/p1'
import { PanoPlugin } from '../PanoPlugin'
import { PanoGLV2 } from '../PanoGLV2'
import { AnkaFetch } from '../AnkaFetch'

TilePlugin.ZOOM_LEVEL_CHANGED = 'onZoomLevelChanged'

TilePlugin.prototype = Object.create(PanoPlugin.prototype)
TilePlugin.prototype.constructor = TilePlugin
TilePlugin._instances = []

TilePlugin.PLAYER_STATUS = 'onPlayerStatusChange'
TilePlugin.PLAYER_STATES = {
    PLAY: 'PLAY',
    STOP: 'STOP'
}

const TO_RAD = Math.PI / 180
function TilePlugin (ops) {
    TilePlugin._instances.push(this)
    PanoPlugin.apply(this, [])
    this.cloneable = false
    this._fps = 5
    this._delay = null
    this._playReverse = false
    this._zoomLevels = [0.6, 0.3, 0.1]
    var t = this
    PanoGLV2.prototype.getTilePlugin = function () {
        return t
    }

    this._allowLoading = false
    this._isDownloading = null
    this.ClassInstance = TilePlugin
    this.initialOptions = ops

    if (ops) {
        for (var str in ops) {
            this['_' + str] = ops[str]
        }
    }

    if (ops && ops.service === undefined) {
        console.error('Options should contain service property')
    }

    this._tileURL = ops.service
}

Object.defineProperties(TilePlugin.prototype, {
    playReverse: {
        set: function (b) { this._playReverse = b },
        get: function () { return this._playReverse }
    },
    loadNextByNext: {
        set: function (v) { console.warn('loadNextByNext deprecated') },
        get: function () { return console.warn('loadNextByNext deprecated') }
    },
    fps: {
        set: function (v) { this._fps = v },
        get: function () { return this._fps }
    },
    isDownloading: {
        set: function (v) { this._isDownloading = v },
        get: function () { return this._isDownloading }
    }
})

TilePlugin.prototype.prepare = function () {
    this._panoState = 'DEFAULT'
    this.tileScene = new THREE.Scene()
    this.containerPivot = new THREE.Object3D()
    this.tileScene.add(this.containerPivot)
    this._cfrustum = new THREE.Frustum()

    var light = new THREE.AmbientLight(0xffffff) // soft white light
    this.tileScene.add(light)
    this.screenLight = light

    let baseObject = this.baseObject
    baseObject.addAmbientLight(light)

    if (!baseObject.initialized) {
        baseObject.addEvent(PanoGLV2.PANOGL_OBJECTS_CREATION_COMPLETED, this, this.creationComplete)
    } else {
        this.creationComplete()
    }
    baseObject.addEvent(PanoGLV2.THUMB_IMAGE_DISPLAYED, this, this.thumbDisplayedResetIt)
    baseObject.addEvent(PanoGLV2.DATA_COMPLETE, this, this.onDataComplete)
    baseObject.addEvent(PanoGLV2.PRE_RENDER, this, this.preRender)
    baseObject.addEvent(PanoGLV2.RENDER_AFTER_SPHERE, this, this.renderAfterSphere)
    baseObject.addEvent(PanoGLV2.ORIENTATION_CHANGED, this, this.onOrientationChanged)
}

TilePlugin.prototype.onOrientationChanged = function (e) {
    var head = -(e.heading + (Math.PI * 0.5))
    var rotHeading = 90 * TO_RAD
    this.containerPivot.rotation.set(e.pitch, rotHeading, -e.roll, 'YXZ')
    this.tileScene.rotation.set(0, head, 0)
}

/**
 * dataları texture'a çevirilmeden önce, methoda gönderip, geri alınır.
 * @property {Function} filterImage
 * @type Function
 * @memberof TilePlugin
 * @param {canvas} cnv;
 * @returns {canvas}
 * @default undefined
 */
TilePlugin.prototype.filterImage = undefined

TilePlugin.prototype.renderAfterSphere = function (e) {
    var bo = this.baseObject
    var renderer = bo.getRenderer()
    var cam = bo.getMainCamera()
    // renderer.clearDepth()
    renderer.render(this.tileScene, cam)
}

TilePlugin.prototype.preRender = function () {
    var controller = this.baseObject.getController()
    var fov = controller.getCurrentFovRatio()
    var zoom = 0
    var i
    for (i = 0; i < this._zoomLevels.length; i++) {
        if (fov > this._zoomLevels[i]) {
            zoom = i
            break
        }
    }

    if (this.currentZoomLevel !== zoom) {
        this.currentZoomLevel = zoom
        this._tiles.forEach(tile => {
            tile.visibleToCamera = false
        })
        this.throwEvent({ type: TilePlugin.ZOOM_LEVEL_CHANGED, level: zoom, fov: fov, levelScale: this._zoomLevels[this._zoomLevels] })
    }

    if (!this._filtered) {
        if (this._allowLoading) {
            this.loadTiles2(zoom)
        }
    }
}

TilePlugin.prototype.loadTiles2 = function (zoom) {
    if (this.isDownloading === null) {
        let tilesToLoad = []
        let tiles = this._tiles

        tiles.forEach(tile => {
            if (tile.visibleToCamera) { // Eğer kameraya görünüyorsa
                if (!tile.isLoading) { // Eğer zaten imaj yüklenmeyi beklemiyorsa
                    if (zoom > tile._lastImageLevel) { // Resmi yoksa
                        tile.setZoom(zoom)
                        tilesToLoad.push(tile)
                    }
                }
            }
        })

        return this.requestForTiles(tilesToLoad)
    }
}

TilePlugin.prototype.requestForTiles = function (tilesToLoad) {
    if (tilesToLoad.length > 0) {
        let tilesToRequest = tilesToLoad.map((tile) => {
            tile.isLoading = true
            return tile.getTileIndexAsString()
        })

        this.isDownloading = this._lastDataID
        let isDownloading = this.isDownloading
        let getParams = tilesToRequest.map(i => 'tile=' + i).join('&')
        let url = this.getDirPath() + '?' + getParams
        url = url.replace('.jpeg', '.srf')
        return AnkaFetch(url)
            .then(e => e.json())
            .then(e => {
                let tileImages = e.images

                if (this._lastDataID === isDownloading) { // eğer request edildiği noktada değilse yükleme
                    for (let i = 0; i < tileImages.length; i++) {
                        const tileImage = tileImages[i]

                        for (let j = 0; j < tilesToLoad.length; j++) {
                            const tile = tilesToLoad[j]
                            if (tile._xIndex === tileImage.x && tile._yIndex === tileImage.y) {
                                tile.isLoading = false
                                tile.setImage(tileImage.image, tileImage.z)
                                tile.show()
                                break
                            }
                        }
                    }

                    this.setDirty()
                }
                this.isDownloading = null
            })
    } else {
        // tile yok
    }
}

TilePlugin.prototype.filterAndLoad = function (zoom = 0) {
    var cam = this.baseObject.getMainCamera()
    var cameraMatrix = cam.projectionMatrix.clone()
    this._cfrustum.setFromMatrix(cameraMatrix.multiply(cam.matrixWorldInverse))
    var t = this._tiles
    for (let i = 0; i < t.length; i++) {
        let tile = t[i]
        if (tile.isDisabled()) {
            continue
        }

        if (this._cfrustum.intersectsObject(tile)) {
            tile.loadOnZoom(true, zoom)
        } else {
            tile.loadOnZoom(false)
        }
    }
}

TilePlugin.prototype.resetForPlay = function () {
    if (this._filtered) {
        return
    }

    clearTimeout(this._playTimeout)
    this._filtered = true
    let panogl = this.baseObject
    panogl.disableGroundNav(true)
    panogl.setArrowVisibility(false)
    panogl.autoUpdateSigns = false
    panogl.setGroundLabelVisibility(false)
    panogl.showColor()

    let current = panogl.getCurrentPoint()
    let otherdata = panogl.getOtherData()
    let dirname = current.dirname
    let sameDir = otherdata.filter(i => i.dirname === dirname)

    let found = this.getNextFrame(sameDir, current)
    this.throwEvent({ type: TilePlugin.PLAYER_STATUS, status: TilePlugin.PLAYER_STATES.PLAY })
    if (found) {
        this._playTimeout = setTimeout(() => {
            this._delay = Date.now()
            panogl.gotoLocation(found.lat, found.lon)
        }, 1000 / this._fps)
    } else {
        this.stopPlaying()
    }
}

TilePlugin.prototype.stopPlaying = function () {
    clearTimeout(this._playTimeout)
    let panogl = this.baseObject
    panogl.autoUpdateSigns = true
    panogl.showImage()
    panogl.disableGroundNav(false)
    panogl.setArrowVisibility(true)
    panogl.setGroundLabelVisibility(true)
    panogl.updateSigns()
    this._filtered = false
    this.isDownloading = null
    this._allowLoading = true
    this.loadTiles2(this.currentZoomLevel)
    this.throwEvent({ type: TilePlugin.PLAYER_STATUS, status: TilePlugin.PLAYER_STATES.STOP })
}

TilePlugin.prototype.getTilesByVisiblity = function () {
    var cam = this.baseObject.getMainCamera()
    var cameraMatrix = cam.projectionMatrix.clone()
    this._cfrustum.setFromMatrix(cameraMatrix.multiply(cam.matrixWorldInverse))
    var tiles = this._tiles
    let visibleTiles = []
    let invisibleTiles = []
    for (let i = 0; i < tiles.length; i++) {
        if (this._cfrustum.intersectsObject(tiles[i])) {
            visibleTiles.push(tiles[i])
        } else {
            invisibleTiles.push(tiles[i])
        }
    }
    return { visibleTiles, invisibleTiles }
}

TilePlugin.prototype.thumbDisplayedResetIt = function () {
    this.isDownloading = null
    this._tiles.map(tile => {
        tile.setRenderFrameID(this._lastDataID)
        tile.hide()
        tile.isLoading = false
    })

    this._allowLoading = true
}

TilePlugin.prototype.onDataComplete = function (event) {
    clearTimeout(this._playTimeout)
    let { data } = event
    let currentPoint = data.current
    this._lastDataID = currentPoint.gid

    if (this._filtered) {
        this._tiles.map(tile => {
            let visibleLastFrame = tile.visibleToCamera
            tile.setRenderFrameID(this._lastDataID)
            if (visibleLastFrame) {
                tile.switchToImageMaterial()
            } else {
                tile.switchToInvisibleMaterial()
            }
            tile.isLoading = false
        })
        
        this.setDirty();
        requestAnimationFrame(() => {
            if (!this._filtered) return

            let zoom = this.currentZoomLevel
            this.isDownloading = null
            this._allowLoading = true

            if (this._allowLoading) {
                let promise = this.loadTiles2(zoom)
                if (promise) {
                    this._allowLoading = false
                    promise.then(e => {
                        let panogl = this.baseObject
                        let current = panogl.getCurrentPoint()
                        panogl.setOriantation(current.heading, current.pitch, current.roll)

                        let prevPoint = panogl.dataParser.prevPoint
                        let prevHeading = 0
                        if (prevPoint) { prevHeading = prevPoint.heading }
                        let controller = panogl.getController()
                        let lastDegree = controller.getRotationYDeg() + prevHeading
                        let currentHeading = current.heading
                        let degreeToLookAt = 0 - (currentHeading - lastDegree)
                        controller.setRotationY(degreeToLookAt / 180 * Math.PI)

                        var _locationChanged = { type: PanoGLV2.LOCATION_CHANGE }
                        _locationChanged.lat = current.lat
                        _locationChanged.lon = current.lon
                        panogl.throwEvent(_locationChanged)

                        if (!this._filtered) return

                        let otherdata = panogl.getOtherData()
                        let dirname = current.dirname
                        let sameDir = otherdata.filter(i => i.dirname === dirname)
                        let found = this.getNextFrame(sameDir, current)
                        if (found) {
                            let timeOut = 1000 / this._fps
                            let delay = (Date.now() - this._delay)

                            if (delay > timeOut) {
                                timeOut -= delay - timeOut
                            }

                            this._delay = Date.now()
                            this._playTimeout = setTimeout(() => {
                                panogl.gotoLocation(found.lat, found.lon)
                            }, timeOut)
                        } else {
                            this.stopPlaying()
                        }
                    })
                }
            }
        })
    }
}

TilePlugin.prototype.getNextFrame = function (sameDir, current) {
    sameDir = sameDir.sort((a, b) => a.frame - b.frame)
    if (!this._playReverse) {
        sameDir = sameDir.reverse()
    }

    let found
    for (let i = 0; i < sameDir.length; i++) {
        let dir = sameDir[i]

        if (this._playReverse) {
            if (dir.frame < current.frame) {
                found = dir
            }
        } else {
            if (dir.frame > current.frame) {
                found = dir
            }
        }
    }
    return found
}

TilePlugin.prototype.creationComplete = function () {
    this._tiles = TilePlugin.GetTiles(this.containerPivot, this.baseObject, -90)
}

TilePlugin.prototype.getTiles = function () {
    return this._tiles
}

TilePlugin.prototype.getDirPath = function () {
    let panoObject = this.baseObject
    let currentPoint = panoObject.getCurrentPoint()
    let { dirname, parent, img } = currentPoint

    dirname = dirname.split(/[/,\\]/).filter(s => s !== '').join('/')
    let url = this._tileURL
    if (parent) {
        parent = parent.split(/[/,\\]/).filter(s => s !== '').join('/')
        url = `${url}${/(\/|\\)$/.test(url) ? '' : '/'}${parent}/${dirname}`
    } else {
        url = `${url}${/(\/|\\)$/.test(url) ? '' : '/'}${dirname}`
    }
    url = `${url}/${img}`
    return url
}

TilePlugin.prototype.getTilePath = function (y, x, z) {
    let panoObject = this.baseObject
    let currentPoint = panoObject.getCurrentPoint()
    let { dirname, parent, img } = currentPoint

    dirname = dirname.split(/[/,\\]/).filter(s => s !== '').join('/')
    let url = this._tileURL
    if (parent) {
        parent = parent.split(/[/,\\]/).filter(s => s !== '').join('/')
        url = `${url}${/(\/|\\)$/.test(url) ? '' : '/'}${parent}/${dirname}`
    } else {
        url = `${url}${/(\/|\\)$/.test(url) ? '' : '/'}${dirname}`
    }
    url = `${url}/${y}/${x}/${z}/${img}`
    return url
}

TilePlugin.GetTiles = function (objectContainer, panoGL, offsetDeg) {
    if (TilePlugin._tileObjects === undefined) {
        TilePlugin._tileObjects = []
        const objLoader = new THREE.OBJLoader()
        const geo0 = objLoader.parse(tile1, '').children[0]
        const geo1 = objLoader.parse(tile2, '').children[0]
        const geo2 = objLoader.parse(tile3, '').children[0]
        const geo3 = objLoader.parse(tile4, '').children[0]
        const geo4 = objLoader.parse(tile5, '').children[0]
        const geo5 = objLoader.parse(tile6, '').children[0]
        const geo6 = objLoader.parse(tile7, '').children[0]
        const geo7 = objLoader.parse(tile8, '').children[0]

        TilePlugin._tileObjects = [geo0.geometry, geo1.geometry, geo2.geometry, geo3.geometry, geo4.geometry, geo5.geometry, geo6.geometry, geo7.geometry]
    }

    var tiles = []
    var sdeg = 22.5
    var pincik0, pincik1, pincik2, pincik3, pincik4, pincik5, pincik6, pincik7, deg
    var to = TilePlugin._tileObjects
    for (var i = 0; i <= 15; i++) {
        var ii = 15 - i
        deg = (offsetDeg + (sdeg * i)) * TO_RAD
        pincik0 = new Tile(to[0], ii, 7, 0)
        objectContainer.add(pincik0)
        pincik0.rotation.y = deg
        tiles.push(pincik0)

        pincik7 = new Tile(to[7], ii, 0, 0)
        objectContainer.add(pincik7)
        pincik7.rotation.y = deg
        tiles.push(pincik7)

        pincik1 = new Tile(to[1], ii, 6, 0)
        objectContainer.add(pincik1)
        pincik1.rotation.y = deg
        tiles.push(pincik1)

        pincik3 = new Tile(to[3], ii, 4, 0)
        objectContainer.add(pincik3)
        pincik3.rotation.y = deg
        tiles.push(pincik3)

        pincik2 = new Tile(to[2], ii, 5, 0)
        objectContainer.add(pincik2)
        pincik2.rotation.y = deg
        tiles.push(pincik2)

        pincik5 = new Tile(to[5], ii, 2, 0)
        objectContainer.add(pincik5)
        pincik5.rotation.y = deg
        tiles.push(pincik5)

        pincik4 = new Tile(to[4], ii, 3, 0)
        objectContainer.add(pincik4)
        pincik4.rotation.y = deg
        tiles.push(pincik4)

        pincik6 = new Tile(to[6], ii, 1, 0)
        objectContainer.add(pincik6)
        pincik6.rotation.y = deg
        tiles.push(pincik6)
    }

    tiles.sort((t1, t2) => t2.loadPriority - t1.loadPriority)
    return tiles
}

export { TilePlugin }
