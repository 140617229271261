import {Base} from './Base'

ExObject.POSITION_CHANGE = 'onPositionChange'
ExObject.CLICK = 'click'

ExObject.prototype = Object.create(THREE.Object3D.prototype)
ExObject.prototype.constructor = ExObject

var exobaseprot = Object.create(Base.prototype)
for (var str in exobaseprot) { ExObject.prototype[str] = exobaseprot[str] }

function ExObject () {
    Base.apply(this, [])
    THREE.Object3D.apply(this, [])
}

ExObject.prototype.setPosition = function (x, y, z) {
    if (this.position.x !== x || this.position.y !== y || this.position.z !== z) {
        this.position.set(x, y, z)
        this.callPositionChangeEvent()
    }
}

ExObject.prototype.setX = function (x) {
    if (this.position.x !== x) {
        this.position.x = x
        this.callPositionChangeEvent()
    }
}

ExObject.prototype.setY = function (y) {
    if (this.position.y !== y) {
        this.position.y = y
        this.callPositionChangeEvent()
    }
}

ExObject.prototype.setZ = function (z) {
    if (this.position.z !== z) {
        this.position.z = z
        this.callPositionChangeEvent()
    }
}

ExObject.prototype.getX = function () {
    return this.position.x
}

ExObject.prototype.getY = function () {
    return this.position.y
}

ExObject.prototype.getZ = function () {
    return this.position.z
}

ExObject.prototype.callPositionChangeEvent = function () {
    if (this.hasEvent(ExObject.POSITION_CHANGE)) { this.throwEvent({type: ExObject.POSITION_CHANGE, target: this}) }
}

export {ExObject}
