import {Base} from './Base'
import {Label} from './Label'
import {TO_RAD} from './Utils'

LabelManager.prototype = Object.create(THREE.Object3D.prototype)
var prot = Object.create(Base.prototype)
for (var str in prot) { LabelManager.prototype[str] = prot[str] }
LabelManager.prototype.constructor = LabelManager
LabelManager.LABEL_DISTANCE = 100
function LabelManager (panoGL, arrowm) {
    this.arrowm = arrowm
    this._aliveLabels = []
    this._deadLabels = []
    this.camera = panoGL.mainCam
    this.panoGL = panoGL
    this.nextRotY = 0
    this.nextRotX = 0
    this.isDirty = true
    THREE.Object3D.apply(this, [])

    this.position.z = -LabelManager.LABEL_DISTANCE
    this.position.y = -50

    arrowm.addEvent('ArrowAddedManager', this, this.arrowAdded)
    arrowm.addEvent('cleanedEvent', this, this.removeAll)
    this.init()
}

LabelManager.prototype.updatePosition = function (e) {
    this.position.y = e.position.y
    this.position.x = e.position.x
    this.position.z = e.position.z
}
var _d
var _l
var i = 0
LabelManager.prototype.arrowAdded = function (e) {
    _d = e.data
    for (i = 0; i < _d.length; i++) {
        _l = this.getLabel()
        _l.setAngle(_d[i].azimuth * TO_RAD)
        _l.setText(_d[i].label)
        this.add(_l)
    }
}

LabelManager.prototype.init = function () {
    this.prepareLabels()
}

LabelManager.prototype.onLoadImage = function () {
    this.isDirty = true
}

LabelManager.prototype.update = function () {
    this.rotation.set(-this.camera.rotation.x, -this.camera.rotation.y, 0)
}

var _lbl
LabelManager.prototype.getLabel = function () {
    if (this._deadLabels.length) {
        _lbl = this._deadLabels.splice(0, 1)[0]
        this._aliveLabels.push(_lbl)
        return _lbl
    }
    _lbl = this.createLabel()
    this._aliveLabels.push(_lbl)
    return _lbl
}

LabelManager.prototype.createLabel = function () {
    _lbl = new Label(this.panoGL)
    return _lbl
}

LabelManager.prototype.prepareLabels = function () {
    for (i = 0; i < 7; i++) {
        this._deadLabels.push(this.createLabel())
    }
}

LabelManager.prototype.removeAll = function () {
    while (this._aliveLabels.length) {
        _lbl = this._aliveLabels[0]
        if (_lbl.parent) {
            this.remove(_lbl)
        }
        this._deadLabels.push(this._aliveLabels.splice(0, 1)[0])
    }
}

export {LabelManager}
