
import { Base } from './Base'
import { Utils, TO_RAD } from './Utils'

function GroundLabelManager (panogl) {
    Base.apply(this, [])
    this.panogl = panogl
    this.labelTextures = {}
    this.scene = new THREE.Scene()
    this.init()
}

GroundLabelManager.prototype = Object.assign(Object.create(Base.prototype),
    {
        constructor: GroundLabelManager,
        init: function () {

        },

        setData: function (data) {
            while (this.scene.children.length > 0) {
                var mesh = this.scene.children[0]
                mesh.material.map.dispose()
                mesh.material.dispose()
                this.scene.remove(mesh)
            }

            if (!this.geomSample) {
                this.geomSample = new THREE.PlaneBufferGeometry(300, 100, 1, 1)
            }

            var s = 0.5
            for (var i = 0; i < data.length; i++) {
                var d = data[i]
                if (!this.labelTextures[d.label]) {
                    this.labelTextures[d.label] = Utils.createTextTexture(d.label)
                }

                var texture = this.labelTextures[d.label]
                var m = new THREE.Mesh(this.geomSample, new THREE.MeshBasicMaterial({ map: texture, depthTest: false, depthWrite: false, transparent: true, opacity: 1 }))
                this.scene.add(m)

                var pos = this.panogl.getPosFromLocation(d.lon, d.lat)
                // console.log(pos);

                // if distance further more than some limits, it returns null
                // if (pos) {
                //     continue
                // }

                m.position.set(i * 100, -100, 100)
                m.position.set(pos.x, pos.y, pos.z)
                var headDif = 360 - d.heading
                var rad = (headDif - 90) * TO_RAD
                m.rotation.set(-(Math.PI / 2), rad, 0, 'YXZ')
                var heighRato = texture.image.width / (texture.image.height * 3)
                m.scale.set(s * heighRato, s, s)
            }
        },

        render: function () {
            this.panogl.getRenderer().clearDepth()
            this.panogl.getRenderer().render(this.scene, this.panogl.getMainCamera())
        }

    })

export { GroundLabelManager }
