
const tile1 = `g __0
usemtl Mat
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0

vt 1 0 0
vt 0.25 0 0
vt 0.5 0 0
vt 0.75 0 0
vt 0 1 0
vt 0.75 0.5 0
vt 0.5 0.5 0
vt 0.75 1 0
vt 0.5 1 0
vt 0.25 1 0
vt 0.5 0.25 0
vt 0.25 0.25 0
vt 1 0.5 0
vt 1 0.25 0
vt 0.75 0.25 0
vt 0.75 0.75 0
vt 0 0.25 0
vt 0 0.5 0
vt 1 1 0
vt 0.25 0.5 0
vt 0.5 0.75 0
vt 0.25 0.75 0
vt 0 0.75 0
vt 1 0.75 0

f 3138/5 3156/23 3155/22 3143/10 
f 3137/4 3148/15 3144/11 
f 3141/8 3149/16 3157/24 3152/19 
f 3140/7 3144/11 3148/15 3139/6 
f 3153/20 3145/12 3144/11 3140/7 
f 3151/18 3150/17 3145/12 3153/20 
f 3137/3 3144/11 3145/12 
f 3149/16 3139/6 3146/13 3157/24 
f 3142/9 3154/21 3149/16 3141/8 
f 3143/10 3155/22 3154/21 3142/9 
f 3155/22 3153/20 3140/7 3154/21 
f 3139/6 3148/15 3147/14 3146/13 
f 3156/23 3151/18 3153/20 3155/22 
f 3137/2 3145/12 3150/17 
f 3137/1 3147/14 3148/15 
f 3154/21 3140/7 3139/6 3149/16`

const tile2 = `g __01
usemtl Mat
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0

vt 0.25 0.5 0
vt 0 0.5 0
vt 1 0.75 0
vt 1 0.5 0
vt 1 1 0
vt 0.75 1 0
vt 1 0.25 0
vt 0.5 1 0
vt 0.25 1 0
vt 1 0 0
vt 0.75 0.25 0
vt 0.5 0.25 0
vt 0.25 0.25 0
vt 0.75 0.75 0
vt 0.5 0.75 0
vt 0.25 0.75 0
vt 0 0.25 0
vt 0 0.75 0
vt 0.75 0 0
vt 0.5 0 0
vt 0.25 0 0
vt 0 0 0
vt 0.75 0.5 0
vt 0.5 0.5 0
vt 0 1 0

f 2717/8 2724/15 2723/14 2715/6 
f 2718/9 2725/16 2724/15 2717/8 
f 2734/25 2727/18 2725/16 2718/9 
f 2733/24 2721/12 2720/11 2732/23 
f 2721/12 2729/20 2728/19 2720/11 
f 2722/13 2730/21 2729/20 2721/12 
f 2715/6 2723/14 2712/3 2714/5 
f 2723/14 2732/23 2713/4 2712/3 
f 2732/23 2720/11 2716/7 2713/4 
f 2726/17 2731/22 2730/21 2722/13 
f 2720/11 2728/19 2719/10 2716/7 
f 2710/1 2722/13 2721/12 2733/24 
f 2724/15 2733/24 2732/23 2723/14 
f 2711/2 2726/17 2722/13 2710/1 
f 2725/16 2710/1 2733/24 2724/15 
f 2727/18 2711/2 2710/1 2725/16 `

const tile3 = `
g __02
usemtl Mat
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0

vt 1 0 0
vt 0.75 0 0
vt 0.5 0 0
vt 0.25 0 0
vt 0.25 0.25 0
vt 0 0 0
vt 0 1 0
vt 1 0.25 0
vt 0.75 0.25 0
vt 0.5 0.25 0
vt 0 0.75 0
vt 0 0.5 0
vt 0 0.25 0
vt 1 0.75 0
vt 0.75 0.75 0
vt 0.5 0.75 0
vt 0.25 0.75 0
vt 0.75 0.5 0
vt 0.5 0.5 0
vt 0.25 0.5 0
vt 1 0.5 0
vt 1 1 0
vt 0.75 1 0
vt 0.5 1 0
vt 0.25 1 0

f 2297/11 2298/12 2306/20 2303/17 
f 2309/23 2301/15 2300/14 2308/22 
f 2310/24 2302/16 2301/15 2309/23 
f 2311/25 2303/17 2302/16 2310/24 
f 2304/18 2295/9 2294/8 2307/21 
f 2295/9 2288/2 2287/1 2294/8 
f 2301/15 2304/18 2307/21 2300/14 
f 2302/16 2305/19 2304/18 2301/15 
f 2293/7 2297/11 2303/17 2311/25 
f 2303/17 2306/20 2305/19 2302/16 
f 2296/10 2289/3 2288/2 2295/9 
f 2291/5 2290/4 2289/3 2296/10 
f 2299/13 2292/6 2290/4 2291/5 
f 2305/19 2296/10 2295/9 2304/18 
f 2306/20 2291/5 2296/10 2305/19 
f 2298/12 2299/13 2291/5 2306/20`

const tile4 = `
g __03
usemtl Mat
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0

vt 1 0.25 0
vt 0.75 0.25 0
vt 0.5 0.25 0
vt 0.25 0.25 0
vt 0 0.25 0
vt 0.75 1 0
vt 0.5 1 0
vt 0.25 1 0
vt 1 0.75 0
vt 1 1 0
vt 0 0 0
vt 0.75 0.75 0
vt 0.5 0.75 0
vt 0.25 0.75 0
vt 0 0.75 0
vt 0 1 0
vt 1 0.5 0
vt 0.75 0.5 0
vt 0.5 0.5 0
vt 0.25 0.5 0
vt 1 0 0
vt 0.75 0 0
vt 0.5 0 0
vt 0.25 0 0
vt 0 0.5 0

f 1948/15 1994/25 1963/20 1947/14 
f 1874/4 1968/24 1967/23 1873/3 
f 1891/6 1944/12 1909/9 1918/10 
f 1893/7 1945/13 1944/12 1891/6 
f 1895/8 1947/14 1945/13 1893/7 
f 1944/12 1961/18 1960/17 1909/9 
f 1963/20 1874/4 1873/3 1962/19 
f 1952/16 1948/15 1947/14 1895/8 
f 1961/18 1872/2 1871/1 1960/17 
f 1887/5 1923/11 1968/24 1874/4 
f 1994/25 1887/5 1874/4 1963/20 
f 1962/19 1873/3 1872/2 1961/18 
f 1872/2 1966/22 1965/21 1871/1 
f 1873/3 1967/23 1966/22 1872/2 
f 1945/13 1962/19 1961/18 1944/12 
f 1947/14 1963/20 1962/19 1945/13 `

const tile5 = `
g __04
usemtl Mat
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0

vt 1 0.75 0
vt 0.75 0.75 0
vt 0.5 0.75 0
vt 0.25 0.75 0
vt 0 0.75 0
vt 0.75 0 0
vt 0.5 0 0
vt 0.25 0 0
vt 1 0.25 0
vt 1 0 0
vt 0 1 0
vt 0.75 0.25 0
vt 0.5 0.25 0
vt 0.25 0.25 0
vt 0 0.25 0
vt 0 0 0
vt 1 0.5 0
vt 0.75 0.5 0
vt 0.5 0.5 0
vt 0.25 0.5 0
vt 1 1 0
vt 0.75 1 0
vt 0.5 1 0
vt 0.25 1 0
vt 0 0.5 0

f 1652/14 1668/20 1699/25 1653/15 
f 1578/3 1672/23 1673/24 1579/4 
f 1623/10 1614/9 1649/12 1596/6 
f 1596/6 1649/12 1650/13 1598/7 
f 1598/7 1650/13 1652/14 1600/8 
f 1614/9 1665/17 1666/18 1649/12 
f 1667/19 1578/3 1579/4 1668/20 
f 1600/8 1652/14 1653/15 1657/16 
f 1665/17 1576/1 1577/2 1666/18 
f 1579/4 1673/24 1628/11 1592/5 
f 1668/20 1579/4 1592/5 1699/25 
f 1666/18 1577/2 1578/3 1667/19 
f 1576/1 1670/21 1671/22 1577/2 
f 1577/2 1671/22 1672/23 1578/3 
f 1649/12 1666/18 1667/19 1650/13 
f 1650/13 1667/19 1668/20 1652/14 
`

const tile6 = `
g __05
usemtl Mat
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0

vt 1 1 0
vt 0.75 1 0
vt 0.5 1 0
vt 0.25 1 0
vt 0.25 0.75 0
vt 0 1 0
vt 0 0 0
vt 1 0.75 0
vt 0.75 0.75 0
vt 0.5 0.75 0
vt 0 0.25 0
vt 0 0.5 0
vt 0 0.75 0
vt 1 0.25 0
vt 0.75 0.25 0
vt 0.5 0.25 0
vt 0.25 0.25 0
vt 0.75 0.5 0
vt 0.5 0.5 0
vt 0.25 0.5 0
vt 1 0.5 0
vt 1 0 0
vt 0.75 0 0
vt 0.5 0 0
vt 0.25 0 0

f 1290/17 1293/20 1285/12 1284/11 
f 1295/22 1287/14 1288/15 1296/23 
f 1296/23 1288/15 1289/16 1297/24 
f 1297/24 1289/16 1290/17 1298/25 
f 1294/21 1281/8 1282/9 1291/18 
f 1281/8 1274/1 1275/2 1282/9 
f 1287/14 1294/21 1291/18 1288/15 
f 1288/15 1291/18 1292/19 1289/16 
f 1298/25 1290/17 1284/11 1280/7 
f 1289/16 1292/19 1293/20 1290/17 
f 1282/9 1275/2 1276/3 1283/10 
f 1283/10 1276/3 1277/4 1278/5 
f 1278/5 1277/4 1279/6 1286/13 
f 1291/18 1282/9 1283/10 1292/19 
f 1292/19 1283/10 1278/5 1293/20 
f 1293/20 1278/5 1286/13 1285/12 `

const tile7 = `g __06
usemtl Mat
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0

vt 0.25 0.5 0
vt 0 0.5 0
vt 1 0.25 0
vt 1 0.5 0
vt 1 0 0
vt 0.75 0 0
vt 1 0.75 0
vt 0.5 0 0
vt 0.25 0 0
vt 1 1 0
vt 0.75 0.75 0
vt 0.5 0.75 0
vt 0.25 0.75 0
vt 0.75 0.25 0
vt 0.5 0.25 0
vt 0.25 0.25 0
vt 0 0.75 0
vt 0 0.25 0
vt 0.75 1 0
vt 0.5 1 0
vt 0.25 1 0
vt 0 1 0
vt 0.75 0.5 0
vt 0.5 0.5 0
vt 0 0 0

f 856/6 864/14 865/15 858/8 
f 858/8 865/15 866/16 859/9 
f 859/9 866/16 868/18 875/25 
f 873/23 861/11 862/12 874/24 
f 861/11 869/19 870/20 862/12 
f 862/12 870/20 871/21 863/13 
f 855/5 853/3 864/14 856/6 
f 853/3 854/4 873/23 864/14 
f 854/4 857/7 861/11 873/23 
f 863/13 871/21 872/22 867/17 
f 857/7 860/10 869/19 861/11 
f 874/24 862/12 863/13 851/1 
f 864/14 873/23 874/24 865/15 
f 851/1 863/13 867/17 852/2 
f 865/15 874/24 851/1 866/16 
f 866/16 851/1 852/2 868/18 `

const tile8 = `
g __07
usemtl Mat
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0
v 0 -500 0
v 176.776695 -461.939766 -73.223305
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 97.545161 -490.39264 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 144.443439 -478.470168 -14.226437
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 93.344899 -490.39264 -28.315866
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 145.142339 -478.470168 0
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 317.196642 -386.505227 0
v 277.785117 -415.734806 0
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 191.341716 -461.939766 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 326.640741 -353.553391 -135.299025
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 369.862441 -317.196642 -112.196545
v 326.640741 -353.553391 -135.299025
v 426.776695 -191.341716 -176.776695
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 407.394503 -235.698368 -168.748328
v 384.088878 -277.785117 -159.094823
v 357.084268 -317.196642 -147.909147
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 421.973015 -235.698368 -128.004115
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 415.734806 -277.785117 0
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 0 500 0
v 0 -500 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 45.278015 497.592363 -18.754768
v 478.470168 -145.142339 0
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 353.553391 353.553391 0
v 317.196642 386.505227 0
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 134.094036 -478.470168 -55.543568
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 317.196642 -386.505227 0
v 442.048795 -145.142339 -183.102606
v 415.734806 277.785117 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 -415.734806 0
v 490.39264 0 -97.545161
v 440.960632 235.698368 0
v 478.470168 0 -145.142339
v 353.553391 -353.553391 0
v 351.850934 -353.553391 -34.654292
v 235.698368 -440.960632 0
v 346.759961 -353.553391 -68.974845
v 461.939766 191.341716 0
v 338.3295 -353.553391 -102.631132
v 45.278015 -497.592363 -18.754768
v 90.119978 -490.39264 -37.328917
v 191.341716 -461.939766 0
v 421.973015 235.698368 -128.004115
v 478.470168 145.142339 0
v 369.862441 -317.196642 -112.196545
v 386.505227 317.196642 0
v 497.592363 -49.00857 0
v 145.142339 -478.470168 0
v 495.19632 49.00857 -48.772581
v 490.39264 97.545161 0
v 234.563416 -440.960632 -23.10248
v 97.545161 -490.39264 0
v 497.592363 49.00857 0
v 231.16949 -440.960632 -45.982471
v 49.00857 -497.592363 0
v 500 0 0
v 225.549276 -440.960632 -68.419625
v 488.031266 49.00857 -97.075454
v 326.640741 -353.553391 -135.299025
v 459.7154 191.341716 -45.278015
v 426.776695 -191.341716 -176.776695
v 442.048795 145.142339 -183.102606
v 476.166203 49.00857 -144.443439
v 97.545161 490.39264 0
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 93.344899 490.39264 -28.315866
v 90.119978 490.39264 -37.328917
v 386.505227 -317.196642 0
v 384.644098 -317.196642 -37.884137
v 379.078637 -317.196642 -75.403429
v 191.341716 461.939766 0
v 453.063723 191.341716 -90.119978
v 407.394503 -235.698368 -168.748328
v 453.063723 97.545161 -187.665139
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 442.048795 191.341716 -134.094036
v 426.776695 191.341716 -176.776695
v 384.644098 317.196642 -37.884137
v 384.088878 -277.785117 -159.094823
v 495.19632 -49.00857 -48.772581
v 488.031266 -49.00857 -97.075454
v 459.7154 49.00857 -190.420354
v 476.166203 -49.00857 -144.443439
v 459.7154 -49.00857 -190.420354
v 326.640741 353.553391 -135.299025
v 379.078637 317.196642 -75.403429
v 357.084268 -317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 384.088878 277.785117 -159.094823
v 490.39264 -97.545161 0
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 357.084268 317.196642 -147.909147
v 461.939766 -191.341716 0
v 459.7154 -191.341716 -45.278015
v 453.063723 -191.341716 -90.119978
v 442.048795 -191.341716 -134.094036
v 217.756898 -440.960632 -90.197861
v 293.051485 -386.505227 -121.3859
v 235.698368 440.960632 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 217.756898 440.960632 -90.197861
v 190.420354 -461.939766 -18.754768
v 145.142339 478.470168 0
v 144.443439 478.470168 -14.226437
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 440.960632 -235.698368 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 369.862441 317.196642 -112.196545
v 453.063723 -97.545161 -187.665139
v 421.973015 -235.698368 -128.004115
v 176.776695 -461.939766 -73.223305
v 407.394503 235.698368 -168.748328
v 277.785117 415.734806 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 415.734806 -277.785117 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 0 -500 0
v 0 500 0
v 49.00857 -497.592363 0
v 48.772581 -497.592363 -4.80368
v 48.066884 -497.592363 -9.561098
v 46.898278 -497.592363 -14.226437
v 45.278015 -497.592363 -18.754768
v 478.470168 145.142339 0
v 476.166203 145.142339 -46.898278
v 469.276498 145.142339 -93.344899
v 457.867403 145.142339 -138.892558
v 353.553391 -353.553391 0
v 317.196642 -386.505227 0
v 315.669254 -386.505227 -31.090708
v 311.101798 -386.505227 -61.881995
v 303.538261 -386.505227 -92.077325
v 134.094036 478.470168 -55.543568
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 351.850934 -353.553391 -34.654292
v 346.759961 -353.553391 -68.974845
v 338.3295 -353.553391 -102.631132
v 317.196642 386.505227 0
v 442.048795 145.142339 -183.102606
v 415.734806 -277.785117 0
v 438.837286 -235.698368 -43.2217
v 432.487697 -235.698368 -86.027152
v 497.592363 0 -49.00857
v 277.785117 415.734806 0
v 490.39264 0 -97.545161
v 440.960632 -235.698368 0
v 478.470168 0 -145.142339
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 461.939766 -191.341716 0
v 338.3295 353.553391 -102.631132
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 421.973015 -235.698368 -128.004115
v 478.470168 -145.142339 0
v 369.862441 317.196642 -112.196545
v 386.505227 -317.196642 0
v 497.592363 49.00857 0
v 145.142339 478.470168 0
v 495.19632 -49.00857 -48.772581
v 490.39264 -97.545161 0
v 234.563416 440.960632 -23.10248
v 97.545161 490.39264 0
v 497.592363 -49.00857 0
v 231.16949 440.960632 -45.982471
v 49.00857 497.592363 0
v 500 0 0
v 225.549276 440.960632 -68.419625
v 488.031266 -49.00857 -97.075454
v 326.640741 353.553391 -135.299025
v 459.7154 -191.341716 -45.278015
v 426.776695 191.341716 -176.776695
v 442.048795 -145.142339 -183.102606
v 476.166203 -49.00857 -144.443439
v 97.545161 -490.39264 0
v 97.075454 -490.39264 -9.561098
v 95.670858 -490.39264 -19.030117
v 93.344899 -490.39264 -28.315866
v 90.119978 -490.39264 -37.328917
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 191.341716 -461.939766 0
v 453.063723 -191.341716 -90.119978
v 407.394503 235.698368 -168.748328
v 453.063723 -97.545161 -187.665139
v 293.051485 -386.505227 -121.3859
v 190.420354 -461.939766 -18.754768
v 442.048795 -191.341716 -134.094036
v 426.776695 -191.341716 -176.776695
v 384.644098 -317.196642 -37.884137
v 384.088878 277.785117 -159.094823
v 495.19632 49.00857 -48.772581
v 488.031266 49.00857 -97.075454
v 459.7154 -49.00857 -190.420354
v 476.166203 49.00857 -144.443439
v 459.7154 49.00857 -190.420354
v 326.640741 -353.553391 -135.299025
v 379.078637 -317.196642 -75.403429
v 357.084268 317.196642 -147.909147
v 461.939766 0 -191.341716
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 413.732929 -277.785117 -40.749137
v 407.746578 -277.785117 -81.105837
v 397.833405 -277.785117 -120.681444
v 384.088878 -277.785117 -159.094823
v 490.39264 97.545161 0
v 488.031266 97.545161 -48.066884
v 480.969883 97.545161 -95.670858
v 469.276498 97.545161 -142.353469
v 357.084268 -317.196642 -147.909147
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 235.698368 -440.960632 0
v 234.563416 -440.960632 -23.10248
v 231.16949 -440.960632 -45.982471
v 225.549276 -440.960632 -68.419625
v 217.756898 -440.960632 -90.197861
v 190.420354 461.939766 -18.754768
v 145.142339 -478.470168 0
v 144.443439 -478.470168 -14.226437
v 142.353469 -478.470168 -28.315866
v 138.892558 -478.470168 -42.132597
v 134.094036 -478.470168 -55.543568
v 187.665139 -461.939766 -37.328917
v 183.102606 -461.939766 -55.543568
v 176.776695 -461.939766 -73.223305
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 476.166203 -145.142339 -46.898278
v 469.276498 -145.142339 -93.344899
v 457.867403 -145.142339 -138.892558
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 369.862441 -317.196642 -112.196545
v 453.063723 97.545161 -187.665139
v 421.973015 235.698368 -128.004115
v 176.776695 461.939766 -73.223305
v 407.394503 -235.698368 -168.748328
v 277.785117 -415.734806 0
v 276.447505 -415.734806 -27.227703
v 272.447553 -415.734806 -54.193188
v 265.823783 -415.734806 -80.636763
v 256.639984 -415.734806 -106.303762
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 488.031266 -97.545161 -48.066884
v 480.969883 -97.545161 -95.670858
v 469.276498 -97.545161 -142.353469
v 415.734806 277.785117 0
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 497.592363 0 -49.00857
v 490.39264 0 -97.545161
v 478.470168 0 -145.142339
v 500 0 0
v 461.939766 0 -191.341716
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 369.862441 317.196642 -112.196545
v 326.640741 353.553391 -135.299025
v 426.776695 191.341716 -176.776695
v 386.505227 317.196642 0
v 384.644098 317.196642 -37.884137
v 379.078637 317.196642 -75.403429
v 407.394503 235.698368 -168.748328
v 384.088878 277.785117 -159.094823
v 357.084268 317.196642 -147.909147
v 440.960632 235.698368 0
v 438.837286 235.698368 -43.2217
v 432.487697 235.698368 -86.027152
v 421.973015 235.698368 -128.004115
v 413.732929 277.785117 -40.749137
v 407.746578 277.785117 -81.105837
v 397.833405 277.785117 -120.681444
v 415.734806 277.785117 0
v 461.939766 191.341716 0
v 459.7154 191.341716 -45.278015
v 453.063723 191.341716 -90.119978
v 442.048795 191.341716 -134.094036
v 265.823783 415.734806 -80.636763
v 256.639984 415.734806 -106.303762
v 317.196642 386.505227 0
v 277.785117 415.734806 0
v 353.553391 353.553391 0
v 351.850934 353.553391 -34.654292
v 235.698368 440.960632 0
v 346.759961 353.553391 -68.974845
v 338.3295 353.553391 -102.631132
v 191.341716 461.939766 0
v 234.563416 440.960632 -23.10248
v 231.16949 440.960632 -45.982471
v 225.549276 440.960632 -68.419625
v 315.669254 386.505227 -31.090708
v 311.101798 386.505227 -61.881995
v 303.538261 386.505227 -92.077325
v 217.756898 440.960632 -90.197861
v 293.051485 386.505227 -121.3859
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 176.776695 461.939766 -73.223305
v 276.447505 415.734806 -27.227703
v 272.447553 415.734806 -54.193188
v 326.640741 353.553391 -135.299025
v 0 500 0
v 176.776695 461.939766 -73.223305
v 97.075454 490.39264 -9.561098
v 95.670858 490.39264 -19.030117
v 190.420354 461.939766 -18.754768
v 187.665139 461.939766 -37.328917
v 183.102606 461.939766 -55.543568
v 48.066884 497.592363 -9.561098
v 46.898278 497.592363 -14.226437
v 97.545161 490.39264 0
v 49.00857 497.592363 0
v 48.772581 497.592363 -4.80368
v 144.443439 478.470168 -14.226437
v 45.278015 497.592363 -18.754768
v 90.119978 490.39264 -37.328917
v 191.341716 461.939766 0
v 93.344899 490.39264 -28.315866
v 142.353469 478.470168 -28.315866
v 138.892558 478.470168 -42.132597
v 134.094036 478.470168 -55.543568
v 145.142339 478.470168 0

vt 1 1 0
vt 0.25 1 0
vt 0.5 1 0
vt 0.75 1 0
vt 0 0 0
vt 0.75 0.5 0
vt 0.5 0.5 0
vt 0.75 0 0
vt 0.5 0 0
vt 0.25 0 0
vt 0.5 0.75 0
vt 0.25 0.75 0
vt 1 0.5 0
vt 1 0.75 0
vt 0.75 0.75 0
vt 0.75 0.25 0
vt 0 0.75 0
vt 0 0.5 0
vt 1 0 0
vt 0.25 0.5 0
vt 0.5 0.25 0
vt 0.25 0.25 0
vt 0 0.25 0
vt 1 0.25 0

f 434/10 446/22 447/23 429/5 
f 435/11 439/15 428/4 
f 443/19 448/24 440/16 432/8 
f 430/6 439/15 435/11 431/7 
f 431/7 435/11 436/12 444/20 
f 444/20 436/12 441/17 442/18 
f 436/12 435/11 428/3 
f 448/24 437/13 430/6 440/16 
f 432/8 440/16 445/21 433/9 
f 433/9 445/21 446/22 434/10 
f 445/21 431/7 444/20 446/22 
f 437/13 438/14 439/15 430/6 
f 446/22 444/20 442/18 447/23 
f 441/17 436/12 428/2 
f 439/15 438/14 428/1 
f 440/16 430/6 431/7 445/21 `

export {tile1, tile2, tile3, tile4, tile5, tile6, tile7, tile8}
