import { Base } from './Base'

PanoPlugin.prototype = Object.create(Base.prototype)
PanoPlugin.prototype.Constructor = PanoPlugin

function PanoPlugin () {
    Base.apply(this, [])
    this.isDirty = true
    this.cloneable = true
    this.ClassInstance = PanoPlugin
}

PanoPlugin.prototype.install = function (panogl) {
    this.baseObject = panogl
    this.prepare()
}

PanoPlugin.prototype.prepare = function (panogl) {
    console.warn(this.toString() + " Class'ı sub class'da override edilmesi gerekiyor.")
}

PanoPlugin.prototype.notifyNewInstance = function () {

}

PanoPlugin.prototype.setDirty = function () {
    this.isDirty = true
}

PanoPlugin.prototype.update = function () {
    this.isDirty = false
}

PanoPlugin.prototype.destroy = function () {
    console.log('destroy not implemented')
}

export { PanoPlugin }
