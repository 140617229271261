import { PanoGL } from './PanoGL'
import { TilePlugin } from './tile/TilePlugin'
import { Utils } from './Utils'
import { CursorManager } from './CursorManager'

// const PANO_STATES = {
//     DEFAULT:'DEFAULT',
//     VIDEO_PLAY:'VIDEO_PLAY',
// }

class PanoGLV2 extends PanoGL {
    constructor (options) {
        super(options)
        this._options = options
        this._resizeMaterials = []
        super.setPlugin(new TilePlugin({ service: options.tileService }))
    }

    init () {
        super.init()
        CursorManager.addDiv(this.contentDV)
    }

    destroy () {
        super.destroy()
        CursorManager.removeDiv(this.contentDV)
    }

    showImage () {
        let image = this.getCurrentImagePath()
        this.mainPanoSphere.showImage(image)
    }

    showColor () {
        this.mainPanoSphere.setColor()
    }

    setPlugin (plugin) {
        if (plugin.toString() === '[TilePlugin Object]') {
            return console.warn('Tile plugin is already integrated with PanoGL V2.')
        }
        super.setPlugin(plugin)
    }

    gotoRecord (recordID) {
        this.dataParser.gotoRecord(recordID)
    }

    getRecRequestURL (recordID, parent, dirname) {
        console.log(this.jsonConenction, parent, dirname)
        var m = Utils.mergeStringForUrl

        let url = m(m(m(this.jsonConenction, parent), dirname), recordID)

        console.log(url)
        return url
    }

    gotoLocation (lat, lon) {
        super.gotoLocation(lat, lon)
    }

    onDataComplete (e) {
        super.onDataComplete(e)
    }

    gotoNextFrame () {
        let cp = this.dataParser.currentPoint
        let dirname = cp.dirname
        let otherPoints = this.dataParser.otherPoints
        let currentFrame = cp.frame
        let tempFrame = currentFrame + 1
        let point
        for (let i = 0; i < otherPoints.length; i++) {
            if (otherPoints[i].dirname === dirname && tempFrame === otherPoints[i].frame) {
                point = otherPoints[i]
            }
        }

        if (point) {
            this.gotoLocation(point.lat, point.lon)
            return true
        }
        return false
    }

    gotoPrevFrame () {
        let cp = this.dataParser.currentPoint
        let dirname = cp.dirname
        let otherPoints = this.dataParser.otherPoints
        let currentFrame = cp.frame
        let tempFrame = currentFrame - 1
        let point
        for (let i = 0; i < otherPoints.length; i++) {
            if (otherPoints[i].dirname === dirname && tempFrame === otherPoints[i].frame) {
                point = otherPoints[i]
            }
        }

        if (point) {
            this.gotoLocation(point.lat, point.lon)
            return true
        }
        return false
    }

    addResizeMaterial (material) {
        var cdv = this.contentDV
        material.resolution.set(cdv.clientWidth, cdv.clientHeight)
        this._resizeMaterials.push(material)
    }

    removeResizeMaterial (material) {
        let index = this._resizeMaterials.indexOf(material)
        if (index > -1) {
            this._resizeMaterials.splice(index, 1)
        }
    }

    resizeAll () {
        var cdv = this.contentDV
        this._resizeMaterials.forEach(material => {
            material.resolution.set(cdv.clientWidth, cdv.clientHeight)
        })

        super.resizeAll()
    }

    /**
    * Mevcut panaroma'nın başlatılmamış ve bağlantılı bir clone'unu çıkartır.
    * @method createConnectedPano
    * @memberof PanoGL
    * @param {String} divname
    * @return {PanoGL}
    * @public
    * @instance
    */
    createConnectedPano (divname) {
        if (!this.dataParser || !this.dataParser.currentPoint) return

        var pano = new PanoGLV2(Object.assign({}, this._options, {
            content: divname
        }))
        pano.allowSecondImage = this.allowSecondImage
        pano.thumbSize = this.thumbSize
        pano.start()
        if (pano.controller) {
            pano.controller.setRotationY(this.mainCam.rotation.y)
        } else {
            pano.addEvent(PanoGL.SETUP_COMLETE, this, this._connectedPanoSetup)
        }

        var i
        var plugins = this.plugins
        for (i = 0; i < plugins.length; i++) {
            let plugin = plugins[i]
            if (plugin.cloneable) {
                var ClassIns = plugin.constructor // .ClassInstance;

                var ops = plugin.initialOptions
                var p = new ClassIns(ops)
                plugin.notifyNewInstance()
                pano.setPlugin(p)
            }
        }

        var c = PanoGL._instances.length
        for (i = 0; i < c; i++) {
            var a = PanoGL._instances[i]
            for (var j = 1; j < c; j++) {
                var b = PanoGL._instances[j]
                if (a !== b) {
                    b.assignPano(a)
                    a.assignPano(b)
                }
            }
        }

        return pano
    }

    getAzimuth () {
        let currentPoint = this.getCurrentPoint()
        if (currentPoint !== null) {
            return 360 - this.controller.getRotationYDeg()
        }
        return 0
    }

    locationToCartesian (lon, lat, alt) {
        if (!this.dataParser || !this.dataParser.currentPoint) return null

        var _currentData = this.dataParser.currentPoint

        if (!_currentData) return null

        var dist = Utils.heversine(_currentData.lat, lat, _currentData.lon, lon)
        var azimuth = ((Utils.bearing(_currentData.lat, _currentData.lon, lat, lon))) % 360
        azimuth = (360 - azimuth) % 360

        var theta = azimuth
        var gm = this.groundMaster
        var hip = (dist * Math.abs(gm.cameraHeightInPixel)) / gm.cameraHeight
        var rad = (360 - (theta + 90)) * Utils.TO_RAD
        var xx = hip * Math.cos(rad)
        var zz = hip * Math.sin(rad)
        var yy = -gm.cameraHeightInPixel

        if (alt !== undefined && alt !== null) {
            alt = alt - _currentData.altitude
            alt = alt / gm.cameraHeight
            yy = alt * gm.cameraHeightInPixel
        }

        return { x: xx, y: yy, z: zz }
    }

    // /**
    //  * @param {number} degree
    //  */
    // set azimuth (degree) {
    //     this.mainCam.rotation.y = (360 - degree) / 180 * Math.PI
    //     this.setDirty()
    // }

    // /**
    //  * @return {number} degree
    //  */
    // get azimuth () {
    //     return 360 - (this.mainCam.rotation.y * 180 / Math.PI)
    // }

    // get verticalAngle () {
    //     return this.mainCam.rotation.x * 180 / Math.PI
    // }

    // set verticalAngle (v) {
    //     this.mainCam.rotation.x = v / 180 * Math.PI
    // }

    set fov (fov) {
        this.controller.setFov(fov)
    }

    get fov () {
        return this.controller.getFov()
    }

    setViewYX (azimuth, verticalAngle) {
        this.controller.setRotationYX((360 - azimuth) / 180 * Math.PI, verticalAngle / 180 * Math.PI)
    }

    /*
    onStateChange({state, prevState}){
        if(prevState === PANO_STATES.VIDEO_PLAY) {
            clearTimeout(this._playTimeout)
            this.setArrowVisibility(true)
            this.setGroundLabelVisibility(true)
            this.disableGroundNav(false)
            // this.thumbSize = {x:512, y:256}
            this.removeEvent(PanoGL.DATA_COMPLETE,this._repeatDataComplete)
            // this.removeEvent(PanoGL.THUMB_IMAGE_DISPLAYED,this._onThumbLoaded)
            this.allowSecondImage = true
        }

        if(state === PANO_STATES.VIDEO_PLAY) {
            this.allowSecondImage = false
            this.setArrowVisibility(false)
            this.setGroundLabelVisibility(false)
            this.disableGroundNav(true)
            pano.mainPanoSphere.setColor()
            this.addEvent(PanoGL.DATA_COMPLETE,this,this._repeatDataComplete)
            let current = this.getCurrentPoint()
            let otherdata = this.getOtherData()
            let dirname = current.dirname
            let sameDir = otherdata.filter(i => i.dirname === dirname)
            let found = this.getNextFrame(sameDir, current)
            clearTimeout(this._playTimeout)
            if(found) {
                this._playTimeout = setTimeout(() => {
                    this.gotoLocation(found.lat, found.lon)
                }, this._playFPS)
            } else {
                this.setState(PANO_STATES.DEFAULT)
            }
            // this.thumbSize = {x:2048, y:1024}
            // this.addEvent(PanoGL.THUMB_IMAGE_DISPLAYED,this,this._onThumbLoaded)
        }
    }

    onDataComplete(e) {
        if(this._state === 'DEFAULT') {
            super.onDataComplete(e)
        } else {
            this._repeatDataComplete(e)
        }
    }

    playReverse(b) {
        this._playReverse = b
    }

    isPlayingReverse() {
        return this._playReverse
    }

    setPlayFPS(v) {
        this._playFPS = 1000 / v
    }

    getPlayFPS() {
        return 1000 / this._playFPS
    }

    _onThumbLoaded(e) {
        let prevPoint = this.dataParser.prevPoint
        let prevHeading = 0
        if(prevPoint) { prevHeading = prevPoint.heading }
        let controller = this.getController()
        let lastDegree = controller.getRotationYDeg() + prevHeading
        let currentHeading = this.getCurrentPoint().heading
        let degreeToLookAt = 0 - (currentHeading - lastDegree)
        controller.setRotationY(degreeToLookAt / 180 * Math.PI)
    }

    getNextFrame(sameDir, current) {
        sameDir = sameDir.sort((a, b) => a.frame - b.frame)
        if(!this._playReverse) {
            sameDir = sameDir.reverse()
        }

        let found
        for (let i = 0; i < sameDir.length; i++) {
            let dir = sameDir[i]

            if(this._playReverse) {
                if(dir.frame < current.frame) {
                    found = dir
                }
            } else {
                if(dir.frame > current.frame) {
                    found = dir
                }
            }

        }
        return found
    }

    _repeatDataComplete(e) {
        let current = this.getCurrentPoint()
        let otherdata = this.getOtherData()
        let dirname = current.dirname
        let sameDir = otherdata.filter(i => i.dirname === dirname)
        let found = this.getNextFrame(sameDir, current)

        // this.getTilePlugin().thumbDisplayedResetIt()
        let tiles = this.getTilePlugin().getVisibleTiles()

        clearTimeout(this._playTimeout)

        tiles = tiles.filter(t => {
            if(t.cordy > 2 && t.cordy < 6) {
                return true
            }
        })

        let tileSize = tiles.length
        let index = 0
        const onLoadTiles = (e) => {
            index++
            let tile = e.target
            let eventer = tile.eventer
            eventer.removeEvent('onImageLoadEnd', onLoadTiles)

            if(index === tileSize) {
                if(found) {

                    let allTimes = this.getTilePlugin().getTiles()
                    allTimes.forEach(t => {
                        if(tiles.indexOf(t) === -1) {
                            t.reset()
                        } else {
                            t.show()
                        }
                    })

                    this._playTimeout = setTimeout(() => {
                        this.gotoLocation(found.lat, found.lon)
                    }, this._playFPS)

                } else {
                    this.setState(PANO_STATES.DEFAULT)
                }
            }
        }

        for (let i = 0; i < tileSize; i++) {
            const tile = tiles[i];
            tile.eventer.addEvent('onImageLoadEnd',onLoadTiles)
            tile.loadOnlyImageOnFirstZoom()
        }
    }

    playOnCurrentRoad (relativeToCar) {
        let state = this._state
        this._relativeToCar = relativeToCar
        if(state !== 'VIDEO_PLAY') {
            this.setState(PANO_STATES.VIDEO_PLAY)
        }
    }

    stopPlaying() {
        this.setState(PANO_STATES.DEFAULT)
    }

    setState (state) {
        let isARealState = Object.values(PANO_STATES).indexOf(state) > -1
        if(isARealState) {
            if(this._state !== state) {
                let prevState = this._state
                this._state = state
                this.throwEvent({type: PanoGL.STATE_CHANGE, state: state, prevState})
            }
        } else {
            console.warn(`${state} is an unknown state`)
        }
    }
    */
}

PanoGLV2.TilePlugin = TilePlugin

export { PanoGLV2 }
