
/**
 * Renderer Class is a Singleton implementation. This class is using "requestAnimationFrame" function to update PanoGL instances.
 * @class Renderer
 *
*/
var Renderer = (function () {
    var allowInitize = false
    function Renderer () {
        if (!allowInitize) {
            allowInitize = false
            throw new Error('Renderer initize edilemez')
        }
    }

    var instance
    Renderer.getInstance = function () {
        if (!instance) {
            allowInitize = true
            instance = new Renderer()
        }
        return instance
    }

    var _tempFNC
    Renderer.prototype.panoInstances = []
    /**
     * @method addPanoGLInstance
     * @param {PanoGL} ins
     * @memberof Renderer
     */
    Renderer.prototype.addPanoGLInstance = function (ins) {
        this.panoInstances.push(ins)
        if (!_tempFNC) {
            _tempFNC = this.update.bind(this)
            requestAnimationFrame(_tempFNC)
        }
    }

    /**
     * for use of removing PanoGL instance to stop rendering.
     * @param {PanoGL} ins PanoGL instance
     * @memberof Renderer
     */
    Renderer.prototype.removePanoGLInstance = function (ins) {
        var index = this.panoInstances.indexOf(ins)
        if (index > -1) {
            this.panoInstances.splice(index, 1)
        }
    }

    Renderer.prototype.update = function (ins) {
        for (var i = 0; i < this.panoInstances.length; i++) {
            this.panoInstances[i].update()
        }

        requestAnimationFrame(_tempFNC)
        // setTimeout(() => {
        //     requestAnimationFrame(_tempFNC)
        // }, 500)
    }

    return Renderer
})()

export { Renderer }
